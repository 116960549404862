import { getAuthState } from 'sparky-framework';
import { APP_BASE_URL_PREFIX, MSP_APP_BASE_URL_PREFIX } from 'src/AppConfig';
import { dynamicImporting } from 'src/extensionHelp';
import { MSP_ENABLED } from 'src/version.js';

const App = async () => {
	await dynamicImporting();
	return import('./app/App.js');
};

const rnConfigRoutes = [
	{
		id: 'panw.fawkes.routes.config.objects.dynamic-block-lists.trouble-shooting-edl',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-block-lists/troubleshooting-edl`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.dynamic-block-lists.trouble-shooting-edl-force-refresh',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-block-lists/troubleshooting-edl-force-refresh`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.bandwidth-management.siteName',
		config: {
			path: '/workflows/remote-networks/setup/bandwidth-management/siteName',
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.bandwidth-management.regionName',
		config: {
			path: '/workflows/remote-networks/setup/bandwidth-management/regionName',
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.advanced-settings.dns-proxy',
		config: {
			path: '/workflows/remote-networks/setup/advanced-settings/dns-proxy',
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.advanced-settings.overlapped-subnets',
		config: {
			path: '/workflows/remote-networks/setup/advanced-settings/overlapped-subnets',
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.trouble-shooting-routing',
		config: {
			path: '/workflows/remote-networks/setup/troubleshooting-routing',
		},
	},
];

const serviceConnectionsConfigRoutes = [
	{
		id: 'panw.fawkes.routes.config.directory-sync-settings.directorySync.settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/directorySync/settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.trusted-certificate-authorities',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/trusted-certificate-authorities`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.expired-certificates',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/expired-certificates`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.panos-system-certificates',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/panos-system-certificates`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.service-connections.setup.advanced-options',
		config: {
			path: '/workflows/service-connections/setup/advanced-options',
		},
	},
	{
		id: 'panw.fawkes.routes.config.service-connections.setup.troubleshooting-routing',
		config: {
			path: '/workflows/service-connections/setup/troubleshooting-routing',
		},
	},
];

const securityServicesRoutes = [
	{
		id: 'panw.fawkes.routes.config.security-services.security-policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/security`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.global-decryption-exclusions',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-sec/decryption/global-decryption-exclusions`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.anti-spyware',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/anti-spyware`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.vulnerability-protection',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/vulnerability-protection`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.wildfire-and-antivirus',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/wildfire-and-antivirus`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.dns-security',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/dns-security`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.url-access-management',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/url-access-management`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.url-access-management.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/url-access-management/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.url-access-management-profile.custom-url-category',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/url-access-management/custom-url-category`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.file-blocking',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/file-blocking`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.http-header-insertion',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/http-header-insertion`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.profile-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/security-profile-groups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.general-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/general-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.decryption',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/decryption`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.bypass-logging-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/bypass-logging-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.custom-web-access-policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/custom-web-access-policy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.default-web-access-policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/default-web-access-policy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.dns-security',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/dns-security`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.detect-command-and-control',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/detect-command-and-control`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.country-block',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/country-block`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.advanced-url',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/advanced-url`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.wildfire-and-malware',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/wildfire-and-malware`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.device-posture.type',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/device-posture/type`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.device-posture.profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/device-posture/profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.vulnerability',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/vulnerability`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.file-control.file-control-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/file-control/file-control-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.web-security.application-groups-with-control',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/web-security/application-groups-with-control`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.decryption',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.decryption.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.decryption.policies',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption/policies`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.decryption.certificate-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption/certificate-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.decryption.global-decryption-exclusions',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/decryption/global-decryption-exclusions`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.non-decryptable-sites-swg',
		config: {
			path: `${APP_BASE_URL_PREFIX}/web-security-config/security-settings/decryption/non-decryptable-sites`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.dos-protection',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/dos-protection`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.dos-protection.dos-protection-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/dos-protection/dos-protection-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.dos-protection.zone-protection-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/dos-protection/zone-protection-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.ai-security',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/ai-security`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.vulnerability-threat-search',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/vulnerability-threat-search`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.anti-spyware-threat-search',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/anti-spyware-threat-search`,
		},
	},
];

const networkPoliciesRoutes = [
	{
		id: 'panw.fawkes.routes.config.security-services.qos',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/qos`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.qos.policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/qos/policy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.qos.profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/qos/profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.qos.interface',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/qos/interface`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.application-override',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/application-override`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.policy-based-forwarding',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/pbf`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.nat',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/nat`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.sdwan-policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/sdwan`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.upstream-proxy-policy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/upstream-proxy-policy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.security-services.upstream-proxy-policy.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/security-services/upstream-proxy-policy/profiles`,
		},
	},
];

const identityServicesRoutes = [
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.rules',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/rules`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.mfa',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/mfa`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.delegation.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/delegation/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.tacacs',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/tacacs`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.ldap',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/ldap`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.saml',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/saml`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.kerberos',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/kerberos`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.radius',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/radius`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.authentication.sequence',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/authentication/sequence`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.directorySync',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/directorySync`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.directorySync.settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/directorySync/settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.identityRedistribution',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/identityRedistribution`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.local-users-and-user-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/local-users-and-user-groups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.local-users-and-user-groups.users',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/local-users-and-user-groups/users`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.identity-and-access-services.local-users-and-user-groups.user-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/identity-and-access-services/local-users-and-user-groups/user-groups`,
		},
	},
];

const objectsRoutes = [
	{
		id: 'panw.fawkes.routes.config.objects.addresses',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/addresses`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.address-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/address-groups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.regions',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/regions`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.applications',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/applications`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.application-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/application-groups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.application-filters',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/application-filters`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.traffic-objects',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/traffic-objects`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.services',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/services`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.service-groups',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/service-groups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.saas-application-management',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/saas-application-management`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.hip-objects',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/hip-objects`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.hip-profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/hip-profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.dynamic-user-group',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-user-group`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.tag',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/tags`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.auto-tag-actions',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/auto-tag-actions`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.syslog',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/syslog`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.http',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/http`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.syslog.serverProfile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/syslog/serverProfile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.http.serverProfile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/http/serverProfile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.snmp.serverProfile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/snmp/serverProfile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.logForwarding.email.serverProfile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/logForwarding/email/serverProfile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.devices',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/devices`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.dynamic-block-lists',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/dynamic-block-lists`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.custom-certificates',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/custom-certificates`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.ssltls',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/ssltls`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.scep',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/scep`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.certificate-management.oscpresponder',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/certificate-management/oscpresponder`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.schedules',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/schedules`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.projects',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/projects`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.device-quarantine-lis',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/device-quarantine-list`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.routing',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/routing`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.edl',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/edl`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.dns-proxy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/dns-proxy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.dag',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/dag`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.user-ip',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/user-ip`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.dug',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/dug`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.user-group',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/user-group`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.troubleshooting.nat',
		config: {
			path: `${APP_BASE_URL_PREFIX}/troubleshooting/nat`,
		},
	},
];

const deviceSettingsRoutes = [
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.zones',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/zones`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.virtual-wire',
		config: {
			path: '/manage/device-settings/virtual-wire',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.ethernet',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/ethernet`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.aggregate-ethernet',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/aggregate-ethernet`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.vlan',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/vlan`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.tunnel',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/tunnel`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.loopback',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/loopback`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.interfaces.cellular',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/interfaces/cellular`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.Apn',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/Apn`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.routing',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/routing`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.ipsec-tunnel',
		config: {
			path: '/manage/device-settings/ipsec-tunnel',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.ipsec-tunnel.vpn-cluster',
		config: {
			path: '/manage/device-settings/ipsec-tunnel/vpn-cluster',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.dhcp',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/dhcp`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.dhcp.dhcpServer',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/dhcp/dhcpServer`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.dhcp.dhcpServerRelay',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/dhcp/dhcpServerRelay`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.routing.logical-router',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/routing/logical-router`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.routing.routing-profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/routing/routing-profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.dns-proxy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/dns-proxy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.portal-gateway',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/portal-gateway`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.agent-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/agent-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.clientless-apps',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/clientless-apps`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.portal',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/portal`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.gateway',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/gateway`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles.clientless-vpn-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles/clientless-vpn-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles.portal-agent-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles/portal-agent-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles.gateway-agent-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles/gateway-agent-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles.authentication-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles/authentication-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.profiles.dhcp-profile',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/profiles/dhcp-profile`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.agent.app-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/agent/app-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.global-protect.agent.tunnel-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/global-protect/agent/tunnel-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.device-setup',
		config: {
			path: '/manage/device-settings/device-setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.proxy',
		config: {
			path: '/manage/device-settings/proxy',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.device-admin',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/device-admin`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.device-admin.administrators',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/device-admin/administrators`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.device-admin.password-profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/device-settings/device-admin/password-profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.workflow.auto-vpn.vpn-clusters',
		config: {
			path: '/manage/workflow/auto-vpn/vpn-clusters',
		},
	},
	{
		id: 'panw.fawkes.routes.config.device-settings.workflow.auto-vpn.general-settings.edit',
		config: {
			path: '/manage/workflow/auto-vpn/general-settings/edit',
		},
	},
];

const paSetupRoutes = [
	/*******************share start********************/
	{
		id: 'panw.fawkes.routes.config.prisma-access.setup',
		config: {
			path: '/workflows/prisma-access/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access.setup.infrastructure-settings',
		config: {
			path: '/workflows/prisma-access/setup/infrastructure-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access.setup.internal-domain-list',
		config: {
			path: '/workflows/prisma-access/setup/internal-domain-list',
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access.setup.traffic-replication',
		config: {
			path: '/workflows/prisma-access/setup/traffic-replication',
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access.setup.hsm-settings',
		config: {
			path: '/workflows/prisma-access/setup/hsm-settings',
		},
	},
	/*******************share end********************/
	/*******************mu start********************/
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup',
		config: {
			path: '/workflows/mobile-users/setup',
		},
	},
	/*******************mu end********************/
	/*******************mu gp start********************/
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup',
		config: {
			path: '/workflows/mobile-users-gp/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-jp.setup.infrastructure-settings',
		config: {
			path: '/workflows/mobile-users-jp/setup/infrastructure-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-jp.setup.pa-location-config',
		config: {
			path: '/workflows/mobile-users-jp/setup/pa-location-config',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.ztna-agent.agent-settings',
		config: {
			path: '/workflows/mobile-users-gp/setup/ztna-agent/agent-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.ztna-agent.client-auth',
		config: {
			path: '/workflows/mobile-users-gp/setup/ztna-agent/client-auth',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.ztna-agent.rollout',
		config: {
			path: '/workflows/mobile-users-gp/setup/ztna-agent/rollout',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.ztna-agent.global-app-settings',
		config: {
			path: '/workflows/mobile-users-gp/setup/ztna-agent/global-app-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.infrastructure-settings',
		config: {
			path: '/workflows/mobile-users-gp/setup/infrastructure-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.clientless-vpn.applications',
		config: {
			path: '/workflows/mobile-users-gp/setup/clientless-vpn/applications',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.pa-location-config',
		config: {
			path: '/workflows/mobile-users-gp/setup/pa-location-config',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.agent-settings',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/agent-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.tunnel-settings',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/tunnel-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.global-app-setting',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/global-app-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.user-authentication',
		config: {
			path: '/workflows/mobile-users-gp/setup/user-authentication',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.user-status.current-users',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/user-status/current-users',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.user-status.last-90-days',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/user-status/last-90-days',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.global-protect-app.user-status.last-30-days',
		config: {
			path: '/workflows/mobile-users-gp/setup/global-protect-app/user-status/last-30-days',
		},
	},
	/*******************mu gp end********************/
	{
		id: 'panw.fawkes.routes.config.mu-device.setup',
		config: {
			path: '/workflows/mu-device/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mu-device.setup.global-protect-app.agent-settings',
		config: {
			path: '/workflows/mu-device/setup/global-protect-app/agent-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary',
		config: {
			path: '/workflows/mobile-users/setup/fp-summary',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.forwarding-profiles',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/forwarding-profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.forwarding-profiles',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/forwarding-profiles`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.user-location',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/user-location`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.user-location',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/user-location`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.destination',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/destination`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.destination',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/destination`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.custom-proxy',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/custom-proxy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.custom-proxy',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/custom-proxy`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.source-application',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/source-application`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.source-application',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/source-application`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users.setup.fp-summary.forwarding-profiles.pac',
		config: {
			path: `/workflows/mobile-users/setup/fp-summary/forwarding-profiles/pac`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.objects.pba.tabs.fp.fp-summary.forwarding-profiles.pac',
		config: {
			path: `${APP_BASE_URL_PREFIX}/objects/pba/tabs/fp/fp-summary/forwarding-profiles/pac`,
		},
	},
	/*******************mu ep start********************/
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup',
		config: {
			path: '/workflows/mobile-users-ep/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup.infrastructure-settings',
		config: {
			path: '/workflows/mobile-users-ep/setup/infrastructure-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup.user-authentication',
		config: {
			path: '/workflows/mobile-users-ep/setup/user-authentication',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup.forwarding-rules',
		config: {
			path: '/workflows/mobile-users-ep/setup/forwarding-rules',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup.pa-location-config',
		config: {
			path: '/workflows/mobile-users-ep/setup/pa-location-config',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep.setup.advanced-settings.security-settings',
		config: {
			path: '/workflows/mobile-users-ep/setup/advanced-settings/security-settings',
		},
	},
	/*******************mu ep end********************/
	/******************* agentless access start *******************/
	{
		id: 'panw.fawkes.routes.config.agentless-access.setup',
		config: {
			path: '/workflows/agentless-access/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.agentless-access.setup.infrastructure-settings',
		config: {
			path: '/workflows/agentless-access/setup/infrastructure-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.agentless-access.setup.user-authentication',
		config: {
			path: '/workflows/agentless-access/setup/user-authentication',
		},
	},
	{
		id: 'panw.fawkes.routes.config.agentless-access.setup.location-config',
		config: {
			path: '/workflows/agentless-access/setup/location-config',
		},
	},
	/******************* agentless access end *******************/
	/*******************RN start********************/
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup',
		config: {
			path: '/workflows/remote-networks/setup',
		},
	},
	...rnConfigRoutes,
	/*******************RN end********************/
	/*******************SC start********************/
	{
		id: 'panw.fawkes.routes.config.service-connections.setup',
		config: {
			path: '/workflows/service-connections/setup',
		},
	},
	...serviceConnectionsConfigRoutes,
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.wan-sites.add',
		config: {
			path: '/workflows/remote-networks/setup/wan-sites/add',
		},
	},
];

const ngfwSetupRoutes = [
	{
		id: 'panw.fawkes.routes.config.ngfw-setup',
		config: {
			path: '/manage/ngfw-setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.workflow.auto-vpn',
		config: {
			path: '/manage/workflow/auto-vpn',
		},
	},
	{
		id: 'panw.fawkes.routes.config.saas-application-endpoints',
		config: {
			path: `${APP_BASE_URL_PREFIX}/saas-application-endpoints`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.saas-application-endpoints.config',
		config: {
			path: `${APP_BASE_URL_PREFIX}/saas-application-endpoints/config`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.notification-template',
		config: {
			path: `${APP_BASE_URL_PREFIX}/notification-template`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.notification-template.notification',
		config: {
			path: `${APP_BASE_URL_PREFIX}/notification-template/notification`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.notification-template.notification-settings',
		config: {
			path: `${APP_BASE_URL_PREFIX}/notification-template/notification-settings`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.rbi.setup',
		config: {
			path: '/workflows/rbi/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.rbi.setup.infra-settings',
		config: {
			path: '/workflows/rbi/setup/infra-settings',
		},
	},
	{
		id: 'panw.fawkes.routes.config.rbi.setup.isolation-profiles',
		config: {
			path: '/workflows/rbi/setup/isolation-profiles',
		},
	},
	{
		id: 'panw.fawkes.routes.config.firewall-setup.device-onboarding',
		config: {
			path: '/workflows/firewall-setup/device-onboarding',
		},
	},
	{
		id: 'panw.fawkes.routes.config.firewall-setup.device-onboarding.rules',
		config: {
			path: '/workflows/firewall-setup/device-onboarding/rules',
		},
	},
	{
		id: 'panw.fawkes.routes.config.firewall-setup.device-onboarding.tabs.deviceLabelGroups',
		config: {
			path: `/workflows/firewall-setup/device-onboarding/tabs/deviceLabelGroups`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.upgrades.software-management',
		config: {
			path: '/workflows/upgrades/software-management',
		},
	},
	{
		id: 'panw.fawkes.routes.config.upgrades.software-management.schedules',
		config: {
			path: '/workflows/upgrades/software-management/schedules',
		},
	},
	{
		id: 'panw.fawkes.routes.config.upgrades.software-management.rule',
		config: {
			path: '/workflows/upgrades/software-management/rules',
		},
	},
];

const operationsRoutes = [
	{
		id: 'panw.fawkes.routes.config.operations.troubleshooting',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operations/troubleshooting`,
		},
	},
];

const managementConfigurationRoutes = [
	{
		id: 'panw.fawkes.routes.config.subscriber-tenants',
		config: {
			path: '/overview/subscriber-tenants',
		},
	},
	{
		id: 'panw.fawkes.routes.config.',
		config: {
			path: `${APP_BASE_URL_PREFIX}/variables`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.trustedTenants',
		config: {
			path: `${APP_BASE_URL_PREFIX}/trustedTenants`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.version-snapshots',
		config: {
			path: '/overview/version-snapshots',
		},
	},
	{
		id: 'panw.fawkes.routes.config.audit-history',
		config: {
			path: '/overview/audit-history',
		},
	},
	{
		id: 'panw.fawkes.routes.config.haconfig',
		config: {
			path: `${APP_BASE_URL_PREFIX}/haconfig`,
		},
	},
	...securityServicesRoutes,
	...networkPoliciesRoutes,
	...identityServicesRoutes,
	...objectsRoutes,
	...deviceSettingsRoutes,
	...operationsRoutes,
	...paSetupRoutes,
	...ngfwSetupRoutes,
];

const easyOnboardingRoutes = [
	{
		id: 'panw.fawkes.routes.config.remote-networks.setup.inbound-access',
		config: {
			path: '/workflows/remote-networks/setup/inbound-access',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp.setup.clientless-vpn',
		config: {
			path: '/workflows/mobile-users-gp/setup/clientless-vpn',
		},
	},
];

const settingsRoutes = [
	...easyOnboardingRoutes,
	{
		id: 'panw.fawkes.routes.config.discovery.folder-structure.device-assignment',
		config: {
			path: '/workflows/discovery/folder-structure/device-assignment',
		},
	},
	{
		id: 'panw.fawkes.routes.config.discovery.folder-structure.folder-management.template',
		config: {
			path: '/workflows/discovery/folder-structure/folder-management/:template?',
		},
	},
	{
		id: 'panw.fawkes.routes.config.discovery.folder-structure.template-selection.template',
		config: {
			path: '/workflows/discovery/folder-structure/template-selection/:template?',
		},
	},
	{
		id: 'panw.fawkes.routes.config.discovery',
		config: {
			path: '/workflows/discovery',
		},
	},
	{
		id: 'panw.fawkes.routes.config.firewall-setup.folder-management',
		config: {
			path: '/workflows/firewall-setup/folder-management',
		},
	},
	{
		id: 'panw.fawkes.routes.config.firewall-setup.device-management',
		config: {
			path: '/workflows/firewall-setup/device-management',
		},
	},
	// Colo Connect
	{
		id: 'panw.fawkes.routes.config.colo-connect',
		config: {
			path: '/workflows/colo-connect',
		},
	},
	{
		id: 'panw.fawkes.routes.config.pa-subnet-mapping',
		config: {
			path: '/workflows/pa-subnet-mapping',
		},
	},
	{
		id: 'panw.fawkes.routes.config.vlan-connection',
		config: {
			path: '/workflows/vlan-connection',
		},
	},
	// Local UDA
	{
		id: 'panw.fawkes.routes.config.local-uda.setup',
		config: {
			path: '/workflows/local-uda/setup',
		},
	},
	{
		id: 'panw.fawkes.routes.config.local-uda.setup.forwarding-rules',
		config: {
			path: '/workflows/local-uda/setup/forwarding-rules',
		},
	},
	{
		id: 'panw.fawkes.routes.config.local-uda.setup.uda-location-config',
		config: {
			path: '/workflows/local-uda/setup/uda-location-config',
		},
	},
	// New onboarding route(s)
	{
		id: 'panw.fawkes.routes.config.onboarding',
		config: {
			path: '/workflows/onboarding',
		},
	},
	{
		id: 'panw.fawkes.routes.config.onboarding.users',
		config: {
			path: '/workflows/onboarding/users',
		},
	},
	{
		id: 'panw.fawkes.routes.config.onboarding.users.agent-based-access',
		config: {
			path: '/workflows/onboarding/users/agent-based-access',
		},
	},
	{
		id: 'panw.fawkes.routes.config.onboarding.users.explicit-proxy',
		config: {
			path: '/workflows/onboarding/users/explicit-proxy',
		},
	},
	{
		id: 'panw.fawkes.routes.config.onboarding.private-apps',
		config: {
			path: '/workflows/onboarding/private-apps',
		},
	},
	{
		id: 'panw.fawkes.routes.config.onboarding.private-apps.service-connection',
		config: {
			path: '/workflows/onboarding/private-apps/service-connection',
		},
	},
];

const overviewWidgetRoutes = [
	{
		id: 'panw.fawkes.routes.config.operation.policy-optimizer.ruleName',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/policy-optimizer/:ruleName`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.optimize.originalBaseRul',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/optimize/:originalBaseRule`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.local-config.device-conflicts',
		config: {
			path: `${APP_BASE_URL_PREFIX}/local-config/device-conflicts`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.local-config.folder-conflicts',
		config: {
			path: `${APP_BASE_URL_PREFIX}/local-config/folder-conflicts`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.local-config.snippet-import',
		config: {
			path: `${APP_BASE_URL_PREFIX}/local-config/snippet-import`,
		},
	},
];

const noneMSPRoutes = [
	{
		id: 'panw.fawkes.routes.config.overview',
		config: {
			path: '/overview',
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access',
		config: {
			path: '/manage/prisma-access',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users',
		config: {
			path: '/manage/mobile-users',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-gp',
		config: {
			path: '/manage/mobile-users-gp',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-jp',
		config: {
			path: '/manage/mobile-users-jp',
		},
	},
	{
		id: 'panw.fawkes.routes.config.mu-device',
		config: {
			path: `${APP_BASE_URL_PREFIX}/mu-device`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.mobile-users-ep',
		config: {
			path: '/manage/mobile-users-ep',
		},
	},
	{
		id: 'panw.fawkes.routes.config.remote-networks',
		config: {
			path: '/manage/remote-networks',
		},
	},
	{
		id: 'panw.fawkes.routes.config.service-connections',
		config: {
			path: '/manage/service-connections',
		},
	},
	{
		id: 'panw.fawkes.routes.config.web-security-config',
		config: {
			path: '/manage/web-security-config',
		},
	},
	{
		id: 'panw.fawkes.routes.config.service-status',
		config: {
			path: '/manage/service-status',
		},
	},
	{
		id: 'panw.fawkes.routes.config.default-manage',
		config: {
			path: '#',
		},
	},
	{
		id: 'panw.fawkes.routes.config..manage',
		config: {
			path: `${APP_BASE_URL_PREFIX}`,
		},
	},
	...settingsRoutes,
	//previous newLeftNav Route
	...overviewWidgetRoutes,
	{
		id: 'panw.fawkes.routes.config.maintenance-mode',
		config: {
			path: '/maintenance-mode',
			contentClassName: 'panorama-root',
		},
	},
	{
		id: 'panw.fawkes.routes.config.panorama',
		config: {
			path: '/panorama',
			contentClassName: 'manage-root panwds-tw3 panorama-root',
		},
	},
	...managementConfigurationRoutes,
	{
		id: 'panw.fawkes.routes.config.iot-policy-recommendation',
		config: {
			path: `${APP_BASE_URL_PREFIX}/iot-policy-recommendation`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.push-config',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/push-config`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.push-status',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/push-status`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.snapshots',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/snapshots`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.config-search',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/config-search`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.prisma-access-license',
		config: {
			path: `${APP_BASE_URL_PREFIX}/prisma-access-license`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.optimize',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/optimize`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.operation.policy-optimizer',
		config: {
			path: `${APP_BASE_URL_PREFIX}/operation/policy-optimizer`,
		},
	},
	{
		id: 'panw.fawkes.routes.config.access-control.scope-management',
		config: {
			path: '/manage/access-control/scope-management',
		},
	},
];

const MSPRoutes = [
	{
		id: 'panw.fawkes.routes.config.overview',
		config: {
			path: `${APP_BASE_URL_PREFIX}/overview`,
		},
	},
	...securityServicesRoutes,
	...identityServicesRoutes,
	...objectsRoutes,
];

const configureRoutes = ({ routes = [], isMSP = false }) => {
	return routes.map((route) => {
		return {
			id: isMSP ? route.id.replace('panw.fawkes.routes.', 'panw.msp-fawkes.routes.') : route.id,
			config: {
				stateLoaders: isMSP ? ['auth', 'fawkesMain', 'msp'] : ['auth', 'fawkesMain'],
				mapStateToProps: (state) => {
					return { main: state.main, theme: state.theme };
				},
				contentClassName: 'manage-root panwds-tw3', // contentClassName should always besides the component, panwds-tw3 is needed for PanWDS
				component: App, // any component must be dynamically import like this
				isAllowed: (...args) => {
					if (!isMSP) {
						return getAuthState()?.instances?.isFawkesRunning;
					} else {
						return true;
					}
				},
				...route.config,
				path: isMSP
					? route.config.path.replace(`${APP_BASE_URL_PREFIX}`, `${MSP_APP_BASE_URL_PREFIX}`)
					: route.config.path,
			},
		};
	});
};

export const getRoutesExtensions = () => {
	const routeExtensions = MSP_ENABLED
		? [...configureRoutes({ routes: [...MSPRoutes], isMSP: true })]
		: [...configureRoutes({ routes: [...noneMSPRoutes] })];
	return routeExtensions;
};
