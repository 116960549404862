export const FAILED = 'failed';
export const PAUSED = 'paused';
export const ABORT = 'abort';
export const FAIL_UNSUPPORTED = 'fail-unsupported';
export const NOT_STARTED = 'not_started';
export const NOT_FOUND = 'not found';
export const STARTING = 'starting';
export const READY = 'ready';
export const NONE = 'None';
export const COMPLETE = 'complete';
export const COMPLETED = 'completed';
export const LOADING = 'LOADING';
export const DONE = 'done';
export const PENDING = 'pending';
export const SUCCESS = 'success';
export const INPROGRESS = 'inprogress';
export const LAST_PUSH_STATUS_CHECK = 'LAST_PUSH_STATUS_CHECK';
export const UPLOAD_CFG_DONE = 'UPLOAD_CFG_DONE';
export const UPLOAD_CFG_FAILED = 'UPLOAD_CFG_FAILED';
export const MIGRATION_IN_PROGRESS = 'MIGRATION_IN_PROGRESS';
export const MIGRATION_NOT_STARTED = 'MIGRATION_NOT_STARTED';
export const FINAL_CONFIRMATION = 'FINAL_CONFIRMATION';
export const COMPLETE_MIGRATION = 'COMPLETE_MIGRATION';
export const MIGRATE_SCM_MODEL = 'migrate_scm_model';
export const ONBOARDING_TENANT = 'tenant-onboarding';
export const VALIDATION = 'validation';
export const CONFIG_DIFF = 'config-diff';
export const GENERATE_CONFIG_DIFF = 'generate_config_diff';

export const MIGRATION_STATUS_TO_STEP_MAP = {
	[LAST_PUSH_STATUS_CHECK]: 1,
	[UPLOAD_CFG_FAILED]: 2,
	[MIGRATION_NOT_STARTED]: 2,
	[UPLOAD_CFG_DONE]: 3,
	[MIGRATION_IN_PROGRESS]: 3,
	[FINAL_CONFIRMATION]: 4,
	[COMPLETE_MIGRATION]: 5,
};

export const diffTypeAndServiceTypeMap = {
	'cs-mu-tab': {
		serviceType: 'Mobile Users',
		type: 'onboarding',
	},
	'cs-ep-tab': {
		serviceType: 'Mobile Users Explicit Proxy',
		type: 'onboarding',
	},
	'cs-rn-tab': {
		serviceType: 'Remote Networks',
		type: 'onboarding',
	},
	'cs-sc-tab': {
		serviceType: 'Service Connections',
		type: 'onboarding',
	},
	'pol-mu-tab': {
		serviceType: 'Mobile Users',
		type: 'config',
	},
	'pol-ep-tab': {
		serviceType: 'Mobile Users Explicit Proxy',
		type: 'config',
	},
	'pol-rn-tab': {
		serviceType: 'Remote Networks',
		type: 'config',
	},
	'pol-sc-tab': {
		serviceType: 'Service Connections',
		type: 'config',
	},
};
